import React, { useCallback } from 'react';
import { LoginOrSignUpModal } from '../src/components/templates/modals/users/login/LoginOrSignUpModal';
import { useRouter } from 'next/router';
import { MainLayout } from '../src/MainLayout';

export default function LoginModalPage() {
  const router = useRouter();

  const handleLogin = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const next = params.get('next');
    // it's important never to navigate off of the site, require relative uris
    if (next && next.startsWith('/')) {
      router.push(next);
    } else {
      router.push('/');
    }
  }, [router]);

  return (
    <MainLayout>
      <LoginOrSignUpModal onLogin={handleLogin} onDismiss={() => {}} />
    </MainLayout>
  );
}
